@font-face {
  font-family: Nunito;
  src: url(shared/fonts/Nunito-VariableFont_wght.ttf);
}
@font-face {
  font-family: Nunito;
  font-style: italic;
  src: url(shared/fonts/Nunito-VariableFont_wght.ttf);
}

body {
  font-family: 'Nunito', sans-serif;
  padding: 0;
  margin: 0;
  background-color: #090909;
  color: #7F7F7F;
  font-weight: 400;
  background-image: url(shared/img/bg.png);
  background-repeat: no-repeat;
  /*width: 100%;*/
  height: 100%;
  background-size: 100vw 100vh;
  background-attachment: fixed;
}

div, p, form, input, a, span, button {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background: #1d1d1e;
  box-shadow: 0 0 8px 3px rgba(0, 0, 0, .5) inset;
}

::-webkit-scrollbar-thumb {
  background: #33373b;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #383c41;
}

.container {
}

.d-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.jc-between {
  justify-content: space-between;
}

.center_w {
  justify-content: center;
}

.center_h {
  -ms-align-items: center;
  align-items: center;
}

.column {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.top {
}

.top_text {
  background: #181818;
  font-size: 2.5vh;
  font-weight: 900;
  color: #3e3e3e;
  height: 10vh;
  padding: 0px 2vh;
  background: #181818;
  width: 74%;
  border-radius: 4vh;
  background-color: #1b2023;
}

.container {
}

.in_chat {
  padding: 3vh;
  margin-top: 3vh;
}

.section2{
  width: 100%;
}

.cyber_btn {
  text-transform: uppercase;
  font-size: 2.5vh;
  color: rgb(94, 94, 94);
  min-height: 10vh;
  width: 80vh;
  height: 15vh;

  padding: 0px 2vh;
  background: rgba(19, 23, 26, 0.75);
  margin: 2vh 0px;
  border-radius: 8vh;
  border: none;
}

.cyber_btn img {
  width: 5.5vh;
  height: 5.5vh;
  opacity: 60%;
  margin-top: 0.4vh;
}

.cyber_btn2 {
  text-transform: uppercase;
  border: none;
  height: 10vh;
  font-size: 2.1vh;
  color: #7F7F7F;
  background: #181818;
  width: 27.6vh;
  border-radius: 2vh;
  margin: 1vh;
}
.cyber_btn2:hover {
  cursor: pointer;
  background: #0e0e0e;
  color: #8f8f8f;
}

.cyber_btn:hover {
  cursor: pointer;
  color: #8f8f8f;
  background: #181d21bf;
}

.cyber_btn3 {
  text-transform: uppercase;
  border: none;
  height: 10vh;
  font-size: 1.9vh;
  color: #676e73;
  background: #181818;
  width: 27.6vh;
  border-radius: 4vh;
  margin: 1vh;
  background-color: #1b2023;
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
}

.cyber_btn3:hover {
  cursor: pointer;
  background-color: #161a1c;
  color: #74797d;
}

.cyber_btn4 {
  text-transform: uppercase;
  border: none;
  height: 8vh;
  font-size: 1.9vh;
  color: #676e73;
  background: #181818;
  width: 27.6vh;
  border-radius: 4vh;
  margin: 1vh;
  background-color: #1b2023;
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
}

.cyber_btn4:hover {
  cursor: pointer;
  background-color: #161a1c;
  color: #74797d;
}

#cyber_input {
  width: 65vh;
  background-color: #1b2023;
  color: #eae4ec;
  border: unset;
  outline: none;
  font-family: Nunito;
  letter-spacing: .07em;
  font-weight: 900;
  padding: 2.2vh 2.2vh 2vh 2.2vh;
  font-size: 1.8vh;
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
}

.btn-complete {
  width: 6vh;
  height: 6vh;
  margin-right: 1vh;
  opacity: 10%;
}

.btn-complete:hover {
  opacity: 50%;
  cursor: pointer;
}

.btn-delete {
  text-transform: uppercase;
  border: none;
  height: 6vh;
  font-size: 2vh;
  color: #7F7F7F;
  background: #181818;
  background: linear-gradient(-45deg, transparent 1vh, #090909 0);
  width: 16.6vh;
}

.btn-delete:hover {
  cursor: pointer;
  background: #181818;
  color: #ff385d;
  background: linear-gradient(-45deg, transparent 1vh, #000000 0);
}

.todo-item {
  font-size: 2.5vh;
  font-weight: 900;
  color: #5e5e5e;
  min-height: 10vh;
  padding: 1vh;
  background: #13171abf;
  /*width: 80vh;*/;
  width: 100%;
  margin: 2vh 0;
  border-radius: 8vh;
  height: 16vh;
  user-select: none;
}

.todo-item:hover  {
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom:0;
  right:0;

  z-index: 2;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  touch-action: none;
  pointer-events: none;
}

.fix-modal
{
  position: relative;
    width:100%;
  height: 100%;
  z-index: 99999;
  pointer-events: none;
}

.modal-content {
  width: 92vh;
  max-height: 90vh;
  z-index: 2;
  color: #eae4ec;
  filter: blur(0);
  transition: filter 0.3s ease;
  background-color: #1c2125;
  border-radius: 4vh;
  /* box-shadow: 4px 4px 4px -3px rgb(114 206 255), -1px -1px 2px 0px rgb(124 208 255), 0px 0px 15px 0px rgb(134 176 255 / 40%), 0px 0px 58px 0px rgb(105 157 255 / 21%); */
  background-color: #323a40;
  box-shadow: 0px 0px 32px 7px rgb(50 58 65 / 70%);
  border: solid 0.9vh #1b2023;
  padding-bottom: 2vh;
  pointer-events: all;

  overflow: hidden;
  padding: 20px;
}

.modal-content2 {

  overflow-y:auto;
}

.close {
  color: #1b2023;
  float: right;
  font-size: 4vh;
  cursor: pointer;
  font-weight: 800;
  margin-right: 1vh;
}

.close:hover {
  color: #6f7579;
}

#cyber_time {
  width: 25vh;
  background-color: #181818;
  color: #eae4ec;
  border: unset;
  outline: none;
  font-family: Nunito;
  letter-spacing: .07em;
  font-weight: 900;
  padding: 2.2vh 2.2vh 2.2vh 2.2vh;
  font-size: 1.8vh;
}


#taskTypeSelect {
  width: 25vh;
  background-color: #181818;
  color: #eae4ec;
  border: unset;
  outline: none;
  font-family: Nunito;
  letter-spacing: .07em;
  font-weight: 900;
  padding: 2.2vh 2.2vh 2.2vh 2.2vh;
  font-size: 1.8vh;
  font-size: 2.5vh;
  font-weight: 900;
  color: #3e3e3e;
  height: 10vh;
  padding: 0px 2vh;
  background: #181818;
  border-radius: 4vh;
  background-color: #1b2023;
  margin-top: 2vh;
}

.glass_box_black {
  background-color: #0000007a;
  border-radius: 10vh 10vh 0 0;
  margin-top: 2vh;
  box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 60%);
}

.glass_box {
  background-color: #57657087;
  border-radius: 10vh 10vh 0 0;
  padding: 8vh;
  width: 80vh;
  min-height: 100vh;
}


.image-container img {
  width: 14vh;
  height: 14vh;
  border-radius: 8vh;
  margin: 0;
  padding: 0;
  display: block;
}

.delete-btn {
  border: none;
  background-color: #1b2024;
  cursor: pointer;
  padding: 0;
  border-radius: 8vh;
  width: 14vh;
  height: 14vh;
  border: solid #394249 0.55vh;
  opacity: 85%;
}

.delete-btn img {
  width: 4vh;
  height: 4vh;
  border-radius: 50%;
  padding-top: 0.5vh;
}

.delete-btn:hover {
  border: solid #3c464d 0.80vh;
  opacity: 100%;
}

.icon img {
  margin-left: 1vh;
  width: 2.5vh;
  height: 2.5vh;
  display: block;
}

.text_info {
  display: flex;
  height: 10vh;
  margin-left: 2vh;
  margin-top: 0vh;
  color: #5e6b75;
}

.description {
  font-weight: 700;
  font-size: 2.6vh;
}

.time {
  font-weight: 700;
  font-size: 2.4vh;
}

.shadow_container {
  width: 79vh;
  height: 13vh;
  background-image: url(shared/img/shadow_container.png);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8c9093;
  font-size: 2.5vh;
  background-size: 100% 100%;
  opacity: 50%;
  padding: 3vh;
  font-weight: 700;

  position: relative;
}

.shadow_container_green {
  width: 79vh;
  height: 13vh;
  background-image: url(shared/img/shadow_container_green.png);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8c9093;
  font-size: 2.5vh;
  background-size: 100% 100%;
  opacity: 50%;
  padding: 3vh;
  font-weight: 700;

  position: relative;
}

.shadow_container_add {
  width: 79vh;
  height: 13vh;
  background-image: url(shared/img/shadow_container.png);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5vh;
  background-size: 100% 100%;
  opacity: 25%;
  padding: 3vh;
}

.shadow_container_add:hover {
  opacity: 34%;
  cursor: pointer;
}

.shadow_container_add img {
  width: 4.5vh;
  height: 4.5vh;
  opacity: 100%;
}

.shadow_container:hover  {
  opacity: 57%;
  cursor: pointer;
}

.shadow_container p {
  margin: 0 ;
}

.cyber_input {
  width: 8vh;
  background-color: #1b2023;
  color: #eae4ec;
  border: unset;
  outline: none;
  font-family: Nunito;
  letter-spacing: .07em;
  font-weight: 900;
  padding: 1.5vh 2vh;
  font-size: 1.8vh;
  border-radius: 3vh;
  margin-right: 1vh;
  height: 8vh;
}

.inputTextGeneral {
  width: 80%;
  background-color: #1b2023;
  color: #eae4ec;
  border: unset;
  outline: none;
  font-family: Nunito;
  letter-spacing: .07em;
  font-weight: 900;
  padding: 1.5vh 2vh;
  font-size: 1.8vh;
  border-radius: 3vh;
  margin-right: 1vh;
  height: 8vh;
}

.imageInput {
  display: none;
}

.image-upload-btn {
  width: 5.5vh;
  height: 5.5vh;
  background-color: #181818;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 1vh;
}

.image-upload-btn {
  width: 15vh;
  height: 15vh;
  background-color: #1f2327;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 1vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.default-image {
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  opacity: 75%;
}

.added-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}



.image-upload-btn:hover {
  background-color: #1b2023;
  opacity: 100%;
}


.modal-content2 #subtaskBtn {
  width: 10vh;
  height: 10vh;
  background-color: #181818;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 1vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal-content2 #subtaskBtn img {
  width: 50%;
  height: 50%;
  border-radius: 50%;
}


.modal-content2 #subtaskBtn:hover {
  background-color: #1b2023;
}

.time_and_image {
  width: 100%;
  padding: 0 6vh;
  margin-top: 1vh;
}

.added-image {
  width: 100%;
  height: 100%;
  border-radius: 8vh;
}

.hidden {
  display: none;
}

.task-type-buttons {
  display: flex;
  margin: 6vh 0 3vh 0;
}

.task-type-btn {
  text-transform: uppercase;
  border: none;
  background: #1b2023;
  width: 22.6vh;
  border-radius: 3vh;
  margin-right: 1vh;
  height: 8vh;
  background-color: #323a40;
  color: #171b1e;
  border: solid 0.6vh #1b2023;
  font-weight: 600;
  font-size: 2.3vh;
}

.task-type-btn:hover {
  cursor: pointer;
  background-color: #1b2023;
  border: none;
  color: #61686c;
  opacity: 75%;
}

.task-type-btn.selected {
  background-color: #1b2023;
  color: #61686c;
  font-weight: 600;
  font-size: 2.3vh;
  border: none;
}

.hidden_item {
  visibility: hidden;
}