.taskCount
{
    margin-right: 2vh;

    color:#5e6b75;
    opacity:0.8;
    font-size: 1.3vh;
    text-transform: uppercase;
    font-weight: 900;
    font-family: 'Nunito';

    height: 10vh;

    display: flex;
    align-items: flex-end;

}

.taskCountInner
{
    height: 2.8vh;
    display: flex;
    align-items: center;
}

.rightCorner
{
    display: flex;
    flex-direction: row;
    align-items: center;
}