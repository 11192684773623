.countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0px;
    padding-top: 2vh;
    width: 120%;
    
    border-top: 2px solid #1b2023;


    span {
        margin-top: 10px;
        line-height: 1.1em;
        color: #1A1E24;
        font-weight: bold;
        font-size: 45px;
    }


}

.infoBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

    div {
        margin: 0px 3px;
        font-size: 20px;
        background-color: #1b1f23;
        border-radius: 16px;
        color: #434C53;
        font-weight: bold;
        padding: 5px 25px;
    }

    div:first-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    div:nth-child(2) {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
}

.progress {
    /* --progress: 0%; */

    width: 75%;
    height: 53px;
    margin: 13px auto;
    border: 5px solid #191E22;
    border-radius: 25px;
    background-color: #272E34;
    display: flex;
    align-items: center;
    padding: 0 3px;
}

.bar {
    border-radius: 25px;
    /* width: 50%; */
    height: 87%;
    background-color: #191E22;
    background-repeat: repeat;
}

/* .progress {
    border-radius: 50px;
    background-color: rgb(28, 119, 25) !important;
    width: 100%;
} */

.processBar {
    margin-bottom: 20px;
    width: 120%;
    padding: 0 15%;
    padding-bottom: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 2px solid #1b2023;

    img {
        margin-right: 5px;
        width: 60px;
        height: 60px;
        border-radius: 50px;

    }


}






#progressBar {
    width: 0%;
    margin: 10px auto;
    height: 22px;
    background-color: #B8C3C3;
}

#progressBar div {
    height: 100%;
    text-align: center;
    width: 0;
    background-color: #4B5863;
    box-sizing: border-box;
}

p {
    text-align: center
}

/* Do not take in account */
html {
    padding-top: 6px
}

a.solink {
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    background: #f3f5f6;
    color: #cfd6d9;
    border: 1px solid #cfd6d9;
    line-height: 30px;
    text-decoration: none;
    transition: all .3s;
    z-index: 999
}

a.solink::first-letter {
    text-transform: capitalize
}

a.solink:hover {
    color: #428bca
}

@media (max-width: 750px) {
    .first_screen {
        height: 70dvh;
        position: relative;
    }

    .arrow_bottom {
        display: none;
    }

    .block_service-text {
        position: relative !important;
        margin-top: -40px;
        grid-area: none !important;
    }

    .grid_layout-main {
        display: block;
    }

    .taxonomy_item {
        flex: 0 0 33.3333%;
    }

    .contact_modal-line {
        flex-direction: column;
    }
}

@media (max-width: 550px) {
    .first_screen {
        height: 50dvh;
        position: relative;
        object-fit: contain;
    }

    .first_screen video {
        object-fit: contain;
    }
}