.daySlider
{
    display: flex;
    align-items: flex-end;
    user-select: none;
}

.daysContainer
{
    display: flex;
}

.dayContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 2vh;
    font-weight: 900;
}

.dayContainerSelected
{
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 2vh;
    font-weight: 900;
}

.day {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #191e22;
    border-radius: 100%;
    width: 6vh;
    height: 6vh;
    font-size: 2vh;
    font-weight: 900;
    color:#5e6b75;
    cursor: pointer;
}

.day:hover {
    opacity: 70%;
}

.dayContainer:not(:last-child), .dayContainerSelected:not(:last-child)
{
    margin-right:2vh;
}

.dayContainerSelected .day
{
    background: transparent;
    border-radius: 100%;

    color:#191e22;

    border: solid 0.5vh #191e22;
}

.dayOfWeek
{
    display: inline-block;
    color: #191e22;
    font-weight: 1000;
    font-size: 2.0vh;

    margin-bottom: 0.75vh;
}

.prev
{
    height: 6vh;
    width: 6vh;

    display: flex;
    justify-content: center;
    align-items: center;
    opacity:0.8;

    margin-right: 1vh;
}

.prev img
{
    height: 4vh;
    width: 4vh;
    transform: rotate(-90.0deg);
    cursor: pointer;
}

.next
{
    height: 6vh;
    width: 6vh;

    display: flex;
    justify-content: center;
    align-items: center;
    opacity:0.8;

    margin-left: 1vh;
}

.next img {
    height: 4vh;
    width: 4vh;
    transform: rotate(90.0deg);
    cursor: pointer;
}

.prev:hover, .next:hover
{
    opacity:1;
}