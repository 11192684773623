.selectable
{
    /*border-radius: 8vh;*/
    opacity: 0.6;
}

.selectable:hover
{
    /*background: rgba(0, 0, 0, 0.37);*/
    opacity: 0.8;
}



.selected
{
    /*border-radius: 8vh;
    background: rgba(0, 0, 0, 0.57);*/
    opacity: 1.0;
    filter: brightness(1.5);
}

.warningNotFound
{
    display: flex;
    justify-content: center;

    font-size: 2.5vh;
    font-weight: 700;
}