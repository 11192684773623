.arrows
{
    position: absolute;
    right: 2vh;

    display: flex;
    flex-direction: column;
    height: 100%;

    justify-content: center;

}

.arrowUp
{
    height: 3vh;
    opacity: 0.8;
}

.arrowDown
{
    height: 3vh;
    transform: scaleY(-1);
    opacity: 0.8;
}

.arrowUp:hover,
.arrowDown:hover
{
    opacity: 1;
}