.cyber_btn2 {
    background: #1c2024;
    border: none;
    border-radius: 2vh;
    color: #7f7f7f;
    font-size: 2.1vh;
    height: 10vh;
    margin: 1vh;
    text-transform: uppercase;
    width: 27.6vh;
}

.cyber_btn2:hover {
    background: #171b1e;
    color: #8f8f8f;
    cursor: pointer;
}

.incomplete-image img {
    cursor: pointer;
    border: 3px solid #1a1f23;
    transition: all linear .17s;
    -webkit-transition: all linear .17s;
    margin: 2px;
}

.incomplete-image img:hover {
    box-shadow: 0px 0px 0px 4px rgb(0 0 0 / 41%);
    border: 3px solid #43779000;
}

.description {
    max-width: 279px;
}

.Countdown_processBar__npj95 {
    flex-wrap: wrap;
}
