.main
{
    display: flex;
    width:100%;
    height:100%;
    justify-content: center;
}

.pages
{
    display: flex;
    justify-content: space-between;
    width:100%;

    margin-bottom: 2vh;

    text-transform: uppercase;
}

.alarmModal {
    
    img {
        width: 200px;
        height: 200px;
        border-radius: 100%;
    }
    

}

.text_info {
    height: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 0px !important;
    align-items: center;


}

.description {
    color: white;
    font-size: 70px !important;
    font-weight: bold;
}

.time {
    font-size: 40px !important;
    font-style: italic;
}

.centered {

}

.pageBtn
{
    width: 23%;
    height: 5vh;

    color: #8ea0b3;

    background: #191e22;

    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    border-radius: 1.5vh;
    font-size: 1.25vh;
    font-weight: 700;

    position:relative;

    user-select: none;
}

.pageBtnInner
{
    top: 0.5vh;
    bottom: 0.5vh;
    left: 0.5vh;
    right: 0.5vh;
    color: #8ea0b3;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    border-radius: 1.0vh;
    font-size: 1.5vh;
    border: solid 3px rgba(142, 160, 179, 0.35);
    cursor: pointer;
    position: absolute;

}

.pageBtn:hover
{
    opacity: 1;
}

.pageBtnSelected
{
    width: 23%;
    height: 5vh;

    color: #8ea0b3;

    background: #191e22;

    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border-radius: 1.5vh;
    font-size: 1.25vh;
    font-weight: 700;

    position:relative;

    user-select: none;
}

.loading
{
    display: flex;
    justify-content: center;

    font-size: 3.25vh;
    font-weight: 700;

    color: #8ea0b3;

    text-transform: uppercase;

    margin-top: 3vh;
}